import "./aws-config";
import {
  signUp,
  signInWithRedirect,
  confirmSignUp,
  signIn,
  fetchAuthSession,
  signOut,
} from "@aws-amplify/auth";
import APIService from "./APIService";

class AuthService {
  async signInWithGoogle() {
    try {
      await signInWithRedirect({ provider: "Google" });
      const { tokens } = await fetchAuthSession();
      const token = tokens.idToken.toString();
      console.log("Token:", tokens.idToken);

      try {
        await APIService.getCurrentUser(token);
      } catch (error) {
        if (error.response.status === 404) {
          await APIService.registerUser(token);
          console.log("User registration successful");
        }
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
      throw error;
    }
  }
  async registerWithEmail(userData) {
    try {
      const { user } = await signUp({
        username: userData.email,
        password: userData.password,
        options: {
          userAttributes: {
            email: userData.email,
            name: userData.name,
          },
        },
      });
      console.log("User registration successful:", user);
      return user;
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  }

  async signInWithEmail(email, password) {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      });

      if (isSignedIn) {
        const { tokens } = await fetchAuthSession();
        const token = tokens.idToken.toString();
        console.log("Token:", tokens.idToken);
        try {
          // Try to get existing user
          const user = await APIService.getCurrentUser(token);
          console.log("Existing user found:", user);
        } catch (error) {
          if (
            error.response?.status === 401 ||
            error.response?.status === 404
          ) {
            // User not found, create new user
            console.log("Creating user with token:", token);
            const newUser = await APIService.registerUser(token);
            console.log("New user created:", newUser);
          }
        }
      }

      console.log("Sign in successful");
      return { isSignedIn, nextStep };
    } catch (error) {
      console.error("Sign in error:", error);
      throw error;
    }
  }

  async verifyEmail(email, code) {
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      return true;
    } catch (error) {
      console.error("Verification error:", error);
      throw error;
    }
  }

  async signOut() {
    try {
      await signOut();
    } catch (error) {
      console.error("Sign out error:", error);
      throw error;
    }
  }
}

export default new AuthService();
