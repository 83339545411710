<template>
  <v-container>
    <h1 class="devcentre-intro">Create your DevCentre Account</h1>
    <p class="devcentre-subintro">
      Join DevCentre to access our full range of development tools and services.
    </p>
    <br />

    <v-card style="border: 2px solid #2ab3b8">
      <v-card-text>
        <!-- Email Registration -->
        <v-text-field
          v-model="name"
          label="Name"
          :disabled="loading"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="email"
          label="Email Address"
          color="grey lighten-2"
          :disabled="loading"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          color="grey lighten-2"
          :disabled="loading"
          :rules="[rules.required, rules.password]"
          hint="Password must be at least 8 characters with upper and lowercase letters, a number, and a symbol"
        ></v-text-field>

        <v-btn
          @click="registerWithEmail"
          :loading="loading"
          :disabled="!isFormValid"
          class="mb-4"
        >
          Register with Email
          <v-icon size="32" color="primary">mdi-email</v-icon>
        </v-btn>

        <v-divider class="my-4"></v-divider>

        <!-- Social Login -->
        <v-btn
          @click="registerWithGoogle"
          :loading="loading"
          block
          class="mt-4"
        >
          Continue with Google
          <v-icon size="32" color="primary">mdi-google</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
  name: "RegisterView",
  data: () => ({
    name: "",
    email: "",
    password: "",
    loading: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || "Invalid email address.";
      },
      password: (value) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const isLongEnough = value.length >= 8;

        if (!isLongEnough) return "Password must be at least 8 characters";
        if (!hasUpperCase) return "Password must contain an uppercase letter";
        if (!hasLowerCase) return "Password must contain a lowercase letter";
        if (!hasNumber) return "Password must contain a number";
        if (!hasSymbol) return "Password must contain a symbol";
        return true;
      },
    },
  }),
  computed: {
    isFormValid() {
      return (
        this.name &&
        this.rules.email(this.email) === true &&
        this.rules.password(this.password) === true
      );
    },
  },
  methods: {
    async registerWithEmail() {
      if (!this.isFormValid) {
        alert("Please fix all form errors before submitting");
        return;
      }

      this.loading = true;
      try {
        await AuthService.registerWithEmail({
          name: this.name,
          email: this.email,
          password: this.password,
        });
        // Redirect to verification page or dashboard
        this.$router.push({ name: "verify-email" });
      } catch (error) {
        console.error("Registration failed:", error);
        alert(error.message || "Registration failed. Please try again.");
      } finally {
        this.loading = false;
      }
    },

    async registerWithGoogle() {
      this.loading = true;
      try {
        await AuthService.signInWithGoogle();
        // Cognito will handle the redirect
      } catch (error) {
        console.error("Google sign-in failed:", error);
        alert(error.message || "Google sign-in failed. Please try again.");
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.mx-4 {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
