import { createVuetify } from "vuetify";
import "vuetify/styles";

// Import any Vuetify components you plan to use
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "devcentreTheme",
    themes: {
      devcentreTheme: {
        colors: {
          primary: "2ab3b8", // Custom primary color
          secondary: "#424242",
          accent: "#82B1FF",
          success: "#4CAF50",
          info: "#2196F3",
          warning: "#FFC107",
          error: "#FF5252",
          background: "#F5F5F5", // Used for page backgrounds
          surface: "#FFFFFF", // Used for elements like cards and menus
        },
      },
    },
  },
});

export default vuetify;
