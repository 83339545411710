import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductsView from "../views/ProductsView.vue";
import ServicesView from "../views/ServicesView.vue";
import ExamplesView from "../views/ExamplesView.vue";
import IntegrationView from "../views/IntegrationView.vue";
import VisualizationView from "../views/VisualizationView.vue";
import AssistantView from "../views/AssistantView.vue";
import RegisterView from "../views/RegisterView.vue";
import VerifyEmailView from "../views/VerifyEmailView.vue";
import LoginView from "../views/LoginView.vue";
import InsightView from "@/views/InsightView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/examples",
    name: "examples",
    component: ExamplesView,
  },
  {
    path: "/integration",
    name: "integration",
    component: IntegrationView,
  },
  {
    path: "/visualization",
    name: "visualization",
    component: VisualizationView,
  },
  {
    path: "/assistant",
    name: "assistant",
    component: AssistantView,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/insight",
    name: "insight",
    component: InsightView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/sent",
    name: "sent",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactSent.vue"),
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmailView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

// Add LinkedIn tracking
router.afterEach((to) => {
  // Make sure lintrk is available
  if (window.lintrk) {
    // Track page view
    window.lintrk("track", {
      conversion_id: to.path,
    });
  }
});

// Initialize LinkedIn tracking script
if (typeof window !== "undefined") {
  window._linkedin_partner_id = "6689868";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

  (function () {
    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })();
}

export default router;
