<template>
  <div class="examples-wrapper">
    <!-- Hero Section -->
    <section class="hero-section py-12">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8" class="text-center">
            <h1 class="text-h2 mb-6" style="color: #2ab3b8; font-weight: 500">
              Success Stories
            </h1>
            <p
              class="text-h5 mx-auto"
              style="color: #37474f; line-height: 1.6; max-width: 800px"
            >
              Discover how we've helped businesses transform their operations
              with our innovative solutions. Here are real-world examples of
              what we can achieve together.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Case Studies Section -->
    <section class="case-studies-section py-8">
      <v-container>
        <!-- ISP Case Study -->
        <v-card
          class="mb-12 case-study-card"
          style="border: 2px solid #2ab3b8; overflow: hidden"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6" class="d-flex align-stretch">
              <v-img
                src="/images/service_person.jpg"
                height="100%"
                cover
                class="case-study-image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <div class="pa-8">
                <div class="d-flex align-center mb-6">
                  <v-icon color="#2ab3b8" size="32" class="mr-4"
                    >mdi-network</v-icon
                  >
                  <h2 class="text-h4" style="color: #2ab3b8">
                    ISP Customer Provisioning
                  </h2>
                </div>

                <div class="mb-6">
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Process Automation</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Partner Integration</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Workflow Management</v-chip
                  >
                </div>

                <div class="mb-6">
                  <h3 class="text-h6 mb-2">Challenge:</h3>
                  <p class="text-body-1 mb-4">
                    Complex customer provisioning process requiring coordination
                    between multiple partners for Fibre network connections.
                  </p>

                  <h3 class="text-h6 mb-2">Solution:</h3>
                  <ul class="text-body-1 mb-4 solution-list">
                    <li>Automated orchestration of service provisioning</li>
                    <li>
                      Integration with partner systems for Fibre installation
                    </li>
                    <li>Automated hardware supply chain management</li>
                    <li>Billing system integration</li>
                    <li>Kanban board implementation for process tracking</li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- Retail B2B Case Study -->
        <v-card
          class="mb-12 case-study-card"
          style="border: 2px solid #2ab3b8; overflow: hidden"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              order="2"
              order-md="1"
              class="d-flex flex-column"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-6">
                  <v-icon color="#2ab3b8" size="32" class="mr-4"
                    >mdi-store</v-icon
                  >
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Retail B2B Integration
                  </h2>
                </div>

                <div class="mb-6">
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >E-commerce</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Warehouse Integration</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Real-time Updates</v-chip
                  >
                </div>

                <div class="mb-6">
                  <h3 class="text-h6 mb-2">Challenge:</h3>
                  <p class="text-body-1 mb-4">
                    Need for seamless integration between e-commerce platform
                    and warehouse management system.
                  </p>

                  <h3 class="text-h6 mb-2">Solution:</h3>
                  <ul class="text-body-1 mb-4 solution-list">
                    <li>Automated order synchronization system</li>
                    <li>Real-time status updates to e-commerce platform</li>
                    <li>Automated inventory level synchronization</li>
                    <li>Batch processing for optimal performance</li>
                  </ul>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              order="1"
              order-md="2"
              class="d-flex align-stretch"
            >
              <v-img
                src="/images/warehouse.jpg"
                height="100%"
                cover
                class="case-study-image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-card>

        <!-- Executive Dashboard Case Study -->
        <v-card
          class="mb-12 case-study-card"
          style="border: 2px solid #2ab3b8; overflow: hidden"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6" class="d-flex align-stretch">
              <v-img
                src="/images/executive_presentation.jpg"
                height="100%"
                cover
                class="case-study-image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <div class="pa-8">
                <div class="d-flex align-center mb-6">
                  <v-icon color="#2ab3b8" size="32" class="mr-4"
                    >mdi-chart-box</v-icon
                  >
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Executive Metrics Dashboard
                  </h2>
                </div>

                <div class="mb-6">
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Data Integration</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Visualization</v-chip
                  >
                  <v-chip class="mr-2 mb-2" color="#2ab3b8" outlined small
                    >Real-time Analytics</v-chip
                  >
                </div>

                <div class="mb-6">
                  <h3 class="text-h6 mb-2">Challenge:</h3>
                  <p class="text-body-1 mb-4">
                    Multinational company needed centralized reporting of key
                    metrics from multiple systems with varying data formats.
                  </p>

                  <h3 class="text-h6 mb-2">Solution:</h3>
                  <ul class="text-body-1 mb-4 solution-list">
                    <li>Centralized data integration system</li>
                    <li>Custom data aggregation pipeline</li>
                    <li>Real-time executive dashboard</li>
                    <li>Large-format display optimization</li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- CTA Section -->
        <v-card color="#2ab3b8" dark class="text-center pa-12">
          <h2 class="text-h3 mb-6">Ready to Build Your Success Story?</h2>
          <p class="text-h6 mb-8">
            Let's discuss how we can transform your business processes
          </p>
          <div class="d-flex justify-center flex-wrap gap-4">
            <v-btn
              x-large
              outlined
              @click="$router.push('/assistant')"
              class="mx-2"
            >
              Try AI Assistant
              <v-icon right>mdi-robot</v-icon>
            </v-btn>
            <v-btn
              x-large
              outlined
              @click="$router.push('/contact')"
              class="mx-2"
            >
              Contact Us
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "ExamplesView",
};
</script>

<style scoped>
.examples-wrapper {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
}

.hero-section {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.case-study-card {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.case-study-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

.case-study-image {
  transition: transform 0.3s ease;
}

.case-study-card:hover .case-study-image {
  transform: scale(1.02);
}

.gap-4 {
  gap: 1rem;
}

.solution-list {
  text-align: left;
  padding-left: 2rem;
  list-style-position: outside;
}

@media (max-width: 960px) {
  .text-h2 {
    font-size: 2.5rem !important;
  }

  .text-h4 {
    font-size: 1.75rem !important;
  }
}
</style>
