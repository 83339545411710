<template>
  <div class="assistant-wrapper">
    <!-- Hero Section -->
    <section class="hero-section py-12 mb-8">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6" class="text-center">
            <h1 class="text-h2 mb-6" style="color: #2ab3b8; font-weight: 500">
              Let's Design Your Software Solution
            </h1>
            <p class="text-h5 mx-auto" style="color: #37474f; line-height: 1.6">
              Chat with our AI advisor to explore your business needs and
              potential features. We'll help you shape your ideas into a clear
              plan for building your application.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Chat Section -->
    <section class="chat-section">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
            <AIAssistant
              prompt_text="Tell me about your project requirements"
              assistant_id="asst_K0iZxs3ISRgPS37EYaEyc65b"
              greeting="Hi! I'm your AI solution advisor. I'll help you explore your software needs and create a plan that matches your business goals. What type of application are you looking to build?"
            ></AIAssistant>
          </v-col>
        </v-row>

        <!-- Feature Cards -->
        <v-row class="mt-12">
          <v-col cols="12" md="4">
            <v-card
              class="feature-card h-100"
              style="border: 2px solid rgba(42, 179, 184, 0.3)"
            >
              <v-card-text class="pa-6 text-center">
                <v-icon color="#2ab3b8" size="48" class="mb-4"
                  >mdi-brain</v-icon
                >
                <h3 class="text-h5 mb-4">AI-Powered Analysis</h3>
                <p class="text-body-1">
                  Our AI assistant analyzes your requirements and suggests
                  optimal solutions based on industry best practices.
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="feature-card h-100"
              style="border: 2px solid rgba(42, 179, 184, 0.3)"
            >
              <v-card-text class="pa-6 text-center">
                <v-icon color="#2ab3b8" size="48" class="mb-4"
                  >mdi-rotate-3d</v-icon
                >
                <h3 class="text-h5 mb-4">Instant Exploration</h3>
                <p class="text-body-1">
                  Explore different approaches and technologies in real-time
                  through natural conversation.
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              class="feature-card h-100"
              style="border: 2px solid rgba(42, 179, 184, 0.3)"
            >
              <v-card-text class="pa-6 text-center">
                <v-icon color="#2ab3b8" size="48" class="mb-4"
                  >mdi-chart-timeline-variant</v-icon
                >
                <h3 class="text-h5 mb-4">Clear Next Steps</h3>
                <p class="text-body-1">
                  Get actionable insights and a structured plan for moving
                  forward with your project.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
  <!-- Contact CTA Section -->
  <v-row class="mt-12">
    <v-col cols="12">
      <v-card
        color="#2ab3b8"
        dark
        class="pa-8 text-center"
        style="border-radius: 8px"
      >
        <h2 class="text-h4 mb-4">Ready to Build Your Solution?</h2>
        <p class="text-h6 font-weight-regular mb-6">
          Let's discuss your project requirements in detail
        </p>
        <v-btn x-large outlined @click="$router.push('/contact')" class="px-8">
          Contact Us
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AIAssistant from "@/components/AIAssistant";

export default {
  name: "AssistantView",
  components: {
    AIAssistant,
  },
};
</script>

<style scoped>
.assistant-wrapper {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
  min-height: 100vh;
}

.hero-section {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.feature-card {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

@media (max-width: 960px) {
  .text-h2 {
    font-size: 2.5rem !important;
  }

  .text-h5 {
    font-size: 1.25rem !important;
  }
}
</style>
