import { Amplify } from "aws-amplify";

console.log("Before configure");

const config = {
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_wJyedCVXI",
      userPoolClientId: "6743hc8e981uuhi1q9dh0pjl89",
      region: "us-east-1",
      signUpVerificationMethod: "code",
      loginWith: {
        oauth: {
          domain: "devcentre.auth.us-east-1.amazoncognito.com",
          scopes: ["openid", "email", "profile"],
          redirectSignIn: ["http://localhost:4200"],
          redirectSignOut: ["http://localhost:4200"],
          responseType: "code",
          providers: ["Google"],
        },
        username: true,
        email: true,
        phone: false,
      },
    },
  },
};

try {
  Amplify.configure(config);
  console.log("After configure success");
} catch (error) {
  console.error("Configure failed:", error);
}
