<template>
  <v-card
    style="border: 2px solid #2ab3b8"
    title="Transform Data into Actionable Insights"
    text="Our visualization expertise makes complex information understandable and decision-making clearer."
  >
    <img src="./images/visualization.jpg" alt="Visualizations" width="90%" />
  </v-card>
</template>

<script></script>
