<template>
  <v-container class="products-container">
    <!-- Header Section -->
    <v-row>
      <v-col cols="12">
        <div class="header-container position-relative">
          <!-- Left Content -->
          <div class="header-content pa-8" style="z-index: 2">
            <h1 class="devcentre-intro mobile-title" style="color: #2ab3b8">
              Our Products
            </h1>
            <p class="devcentre-subintro">Intelligent business solutions</p>
          </div>

          <!-- Right Image with Gradient -->
          <div class="header-image-container">
            <div class="gradient-overlay"></div>
            <v-img
              src="/images/presenter_female_c_cropped.jpg"
              height="300"
              class="rounded-lg header-image"
              alt="Dynamic Insight - AI-Powered Customer Research"
            ></v-img>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Dynamic Insight Featured Product -->
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card
          style="border: 2px solid #2ab3b8; border-radius: 8px"
          class="featured-product"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-img
                src="/images/relaxed_character.jpg"
                height="100%"
                class="mb-6 rounded-lg"
                alt="Dynamic Insight"
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-6">
              <h2 class="text-h4 mb-4" style="color: #2ab3b8">
                Dynamic Insight
              </h2>
              <p class="text-h6 mb-6">
                Transform your customer research with AI-powered conversations
              </p>
              <div class="mb-6">
                <v-chip
                  v-for="feature in features"
                  :key="feature"
                  class="mr-2 mb-2"
                  outlined
                  color="#2ab3b8"
                >
                  {{ feature }}
                </v-chip>
              </div>
              <p class="mb-6">
                Get closer to your customers with Dynamic Insight – the ultimate
                tool for understanding what truly drives them. Forget static
                surveys and rigid forms; our friendly chatbot transforms
                research into an engaging conversation, encouraging customers to
                share honest thoughts in their own words.
              </p>
              <v-btn
                color="#2ab3b8"
                dark
                class="mb-4"
                @click="$router.push('/insight')"
              >
                Learn More
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Coming Soon Section -->
    <v-row>
      <v-col cols="12">
        <h2 class="text-h4 text-center mb-8" style="color: #2ab3b8">
          Coming Soon
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(product, index) in upcomingProducts"
        :key="index"
        cols="12"
        md="4"
        class="mb-6"
      >
        <v-card
          class="h-100 coming-soon-card"
          style="border: 2px solid rgba(42, 179, 184, 0.3); border-radius: 8px"
        >
          <v-img
            :src="product.image"
            height="200"
            class="rounded-t-lg"
            :alt="product.title"
          >
            <div
              class="fill-height coming-soon-overlay d-flex align-center justify-center"
            >
              <v-chip color="#2ab3b8" dark>Coming Soon</v-chip>
            </div>
          </v-img>
          <v-card-title class="text-h6">{{ product.title }}</v-card-title>
          <v-card-text>
            <p>{{ product.description }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Beta Program Banner -->
    <v-row class="mt-12">
      <v-col cols="12">
        <v-card color="#2ab3b8" dark class="pa-8 text-center">
          <h2 class="text-h4 mb-4">Join Our Journey</h2>
          <p class="text-h6 font-weight-regular mb-6">
            Be the first to know when we launch new products and features
          </p>
          <v-btn outlined large @click="$router.push('/insight')">
            Join Beta Program
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProductsView",
  data() {
    return {
      features: [
        "AI-Driven Conversations",
        "Quick Setup",
        "Rich Analytics",
        "Natural Dialogue",
        "Real-time Insights",
      ],
      upcomingProducts: [
        {
          title: "Process Automation",
          image: "/images/objective_complete.jpg",
          description:
            "Streamline your business processes with intelligent automation and workflow management.",
        },
        {
          title: "Integration Designer",
          image: "/images/integration.jpg",
          description:
            "Connect your business systems seamlessly with our visual integration builder.",
        },
        {
          title: "Assistant Builder",
          image: "/images/ai_assistant.jpg",
          description:
            "Create custom AI assistants to enhance your customer engagement.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.products-container {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
  min-height: 100vh;
  padding-top: 2rem;
}

.devcentre-intro {
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.devcentre-subintro {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #4a5568;
}

.featured-product {
  transition: all 0.3s ease;
}

.featured-product:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

.coming-soon-card {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.coming-soon-card:hover {
  opacity: 1;
  transform: translateY(-4px);
}

.coming-soon-overlay {
  background: rgba(42, 179, 184, 0.1);
}

/* Header specific styles */
.header-container {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.header-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-image-container {
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
}

.gradient-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  z-index: 1;
}

.header-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Mobile Styles */
@media (max-width: 1264px) {
  .mobile-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .mobile-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }

  .products-container {
    padding-top: 1rem;
  }

  .header-content {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
  }

  .header-image-container {
    width: 100%;
  }
}
</style>
