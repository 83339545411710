<template>
  <v-container>
    <h1 class="devcentre-intro">Verify Your Email</h1>
    <p class="devcentre-text">
      Please check your email for a verification code and enter it below.
    </p>
    <br />

    <v-card style="border: 2px solid #2ab3b8">
      <v-card-text>
        <v-text-field
          v-model="email"
          label="Email Address"
          :disabled="loading"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          v-model="verificationCode"
          label="Verification Code"
          :disabled="loading"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn @click="verifyEmail" :loading="loading" class="mb-4">
          Verify Email
          <v-icon size="32" color="primary">mdi-check-circle</v-icon>
        </v-btn>

        <div v-if="error" class="error-message mt-4">
          {{ error }}
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
  name: "VerifyEmailView",
  data: () => ({
    email: "",
    verificationCode: "",
    loading: false,
    error: null,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  methods: {
    async verifyEmail() {
      if (!this.email || !this.verificationCode) {
        this.error = "Please fill in all fields";
        return;
      }

      this.loading = true;
      this.error = null;

      try {
        await AuthService.verifyEmail(this.email, this.verificationCode);
        // Redirect to login or dashboard after successful verification
        this.$router.push({ name: "login" });
      } catch (error) {
        console.error("Verification error:", error);
        this.error = error.message || "Verification failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #ff5252;
  font-size: 0.875rem;
}
</style>
