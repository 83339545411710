<template>
  <v-app id="inspire">
    <v-app-bar flat color="primary">
      <v-img
        alt="DevCentre logo"
        src="@/assets/devcentrelogo.png"
        class="mr-5"
        background-color="primary"
        max-width="60"
      />

      <router-link to="/" class="devcentre-title">
        <h1>{{ title }}</h1>
      </router-link>

      <v-spacer></v-spacer>
      <v-btn
        v-for="link in links"
        :key="link"
        :text="link.label"
        :to="link.uri"
        class="hidden-sm-and-down"
        >{{ link.label }}</v-btn
      >

      <!-- Add user menu -->
      <v-menu v-if="currentUser">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar color="surface-variant">
              {{ currentUser.name.charAt(0).toUpperCase() }}
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signOut">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- ENABLE ONCE SINGIN WORKING
       <v-btn v-else to="/login" class="hidden-sm-and-down"> Sign In </v-btn> -->

      <!-- Mobile hamburger menu -->
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- Mobile navigation drawer -->
    <!-- Add sign in to mobile drawer if not logged in -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item v-if="currentUser">
          <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-else to="/login" link>
          <v-list-item-title>Sign In</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="link in links" :key="link.uri" :to="link.uri" link>
          <v-list-item-title>{{ link.label }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="currentUser" @click="signOut" link>
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg-grey-lighten-3" fill-height>
      <!-- Use the v-container directly inside v-main with the "fill-height" property -->
      <v-container fill-height class="align-center justify-center" fluid>
        <!-- Wrap the content in a flex row that centers items vertically and horizontally -->
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8">
            <!-- Adjusted for centering -->
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="#2ab3b8" style="max-height: 60px" padless>
      <v-container fill-height class="align-center justify-center" fluid>
        <v-btn text="About" variant="text" to="/about"></v-btn>
        <v-btn text="Contact" variant="text" to="/contact"></v-btn>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import AuthService from "./services/AuthService";
import { fetchUserAttributes } from "@aws-amplify/auth";
import eventBus from "@/plugins/eventBus";

export default {
  name: "App",
  components: {},
  data: () => ({
    title: "DevCentre",
    drawer: false,
    currentUser: null,
    logo_url: "@/assets/devcentrelogo.png",
    links: [
      { label: "Home", uri: "/" },
      { label: "Services", uri: "/services" },
      { label: "Products", uri: "/products" },
      { label: "Examples", uri: "/examples" },
      { label: "Assistant", uri: "/assistant" },
      { label: "Contact", uri: "/contact" },
      { label: "About", uri: "/about" },
    ],
  }),
  async created() {
    this.fetchUser();
    eventBus.on("user-signed-in", this.fetchUser);
  },
  beforeUnmount() {
    eventBus.off("user-signed-in", this.fetchUser);
  },
  methods: {
    async signOut() {
      try {
        await AuthService.signOut();
        this.currentUser = null;
        this.drawer = false;
        eventBus.emit("user-signed-in");
        this.$router.push("/login");
      } catch (error) {
        console.error("Sign out error:", error);
      }
    },
    async fetchUser() {
      try {
        const attributes = await fetchUserAttributes();
        console.log("Attributes:", attributes);

        if (attributes) {
          this.currentUser = {
            name: attributes.name,
            email: attributes.email,
          };
        }
      } catch (error) {
        console.log("No active session");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(0, 10, 69);
}

#nav-bar {
  background-color: #2ab3b8;
  color: white;
  padding: 50px;
}

nav {
  padding: 50px;
  background-color: #2ab3b8;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-text-field.solo-inverted {
  background-color: #333; /* Dark background color */
  border-radius: 4px; /* Slight rounding of corners */
  color: white; /* Text color */
}

.v-text-field.solo-inverted::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Lighter placeholder text */
}

.v-text-field .v-icon {
  color: white; /* Icon color */
}

.v-carousel-item {
  position: relative;
}

.devcentre-title {
  text-decoration: none;
  color: white;
}

.devcentre-intro {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  background: linear-gradient(45deg, #2ab3b8, #1e7b80);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.devcentre-subintro {
  font-size: 1 rem;
  line-height: 1.6;
  color: #37474f;
}

.devcentre-text {
  font-size: 1 rem; /* About 20px */
  text-align: left;
  color: #0a1a1b;
}

/* Add media queries for responsiveness */
@media (max-width: 600px) {
  .devcentre-intro {
    font-size: 2.5rem; /* Smaller for mobile */
  }

  .devcentre-subintro {
    font-size: 1.5rem; /* Smaller for mobile */
  }

  .devcentre-text {
    font-size: 0.8rem; /* About 16px on mobile */
  }
}

@media (min-width: 600px) {
  .devcentre-intro {
    font-size: 3.5rem;
  }

  .devcentre-subintro {
    font-size: 2rem;
  }

  .devcentre-text {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  .devcentre-intro {
    font-size: 5rem; /* Larger for larger screens */
  }

  .devcentre-subintro {
    font-size: 2rem;
  }

  .devcentre-text {
    font-size: 1.5rem;
  }
}

.rounded-image {
  border-radius: 20px;
}

.image-header-span {
  background-color: rgba(18, 72, 87, 0.5);
  color: white;
  font-weight: bold;
  margin-right: 10;
  padding: 1% 1% 1% 1%;
  float: right;
}
</style>
