<template>
  <div class="home-wrapper">
    <!-- Hero Section with Background -->
    <section class="hero-section">
      <v-container>
        <v-row align="center" class="min-height-70vh">
          <v-col cols="12" md="7" lg="6">
            <h1 class="devcentre-intro mb-6">
              Enterprise Software Solutions & Integration
            </h1>
            <p class="devcentre-subintro mb-8">
              We build custom software solutions that bring people and systems
              together. Our development expertise in AI, automation,
              integration, and visualization helps organizations innovate -
              turning complex business requirements into powerful, scalable
              applications.
            </p>
            <div class="d-flex flex-wrap gap-4 justify-center">
              <v-btn
                color="#2ab3b8"
                dark
                x-large
                to="/assistant"
                elevation="2"
                class="px-6"
              >
                Try AI Assistant
                <v-icon right>mdi-robot</v-icon>
              </v-btn>
              <v-btn
                outlined
                x-large
                to="/contact"
                class="px-6"
                style="border-color: #2ab3b8; color: #2ab3b8"
              >
                Talk to Expert
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            lg="6"
            class="d-none d-md-flex justify-center"
          >
            <v-img
              src="/images/visualization.jpg"
              max-width="500"
              class="hero-image rounded-lg"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Features Grid Section -->
    <section class="py-16 features-section">
      <v-container>
        <v-row class="mb-12">
          <v-col cols="12" class="text-center">
            <h2 class="text-h3 mb-3" style="color: #2ab3b8">
              Our Capabilities
            </h2>
            <p class="text-h6 mx-auto" style="max-width: 700px; color: #37474f">
              Comprehensive solutions that drive business transformation
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(feature, index) in features"
            :key="index"
            cols="12"
            md="6"
            class="mb-8"
          >
            <v-card
              class="feature-card h-100"
              style="border: 2px solid #2ab3b8"
              elevation="0"
            >
              <v-img
                :src="feature.image"
                :alt="feature.alt"
                height="300"
                class="feature-image"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-card-text class="pa-6">
                <h3 class="text-h4 mb-4" style="color: #2ab3b8">
                  {{ feature.title }}
                </h3>
                <p
                  class="text-body-1 mb-6"
                  style="line-height: 1.8; min-height: 80px"
                >
                  {{ feature.description }}
                </p>
                <div class="d-flex flex-wrap">
                  <v-chip
                    v-for="tag in feature.tags"
                    :key="tag"
                    class="mr-2 mb-2"
                    color="#2ab3b8"
                    outlined
                    small
                  >
                    {{ tag }}
                  </v-chip>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Why Choose Us Section -->
    <section class="py-16 bg-gradient">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center mb-12">
            <h2 class="text-h3 mb-3" style="color: #2ab3b8">Why Choose Us</h2>
            <p class="text-h6" style="color: #37474f">
              Partner with us for your digital transformation journey
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(benefit, index) in benefits"
            :key="index"
            cols="12"
            md="4"
            class="text-center"
          >
            <v-icon size="64" color="#2ab3b8" class="mb-4">
              {{ benefit.icon }}
            </v-icon>
            <h3 class="text-h5 mb-4">{{ benefit.title }}</h3>
            <p class="text-body-1">{{ benefit.description }}</p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- CTA Section -->
    <section class="py-16">
      <v-container>
        <v-card color="#2ab3b8" dark class="text-center pa-12">
          <h2 class="text-h3 mb-6">Ready to Transform Your Business?</h2>
          <p class="text-h6 mb-8">
            Start with our AI assistant or speak directly with our experts
          </p>
          <div class="d-flex flex-wrap justify-center gap-4">
            <v-btn x-large outlined to="/assistant" class="px-6">
              Try AI Assistant
              <v-icon right>mdi-robot</v-icon>
            </v-btn>
            <v-btn x-large outlined to="/contact" class="px-6">
              Contact Expert
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data: () => ({
    features: [
      {
        title: "Expert Software Solutions",
        description:
          "Our experienced experts can tackle your unique business needs with our adaptable suite of technologies.",
        image: "/images/user_and_professional.jpg",
        alt: "Expert Software Solutions",
        tags: [
          "Custom Development",
          "Enterprise Solutions",
          "Expert Consultation",
        ],
      },
      {
        title: "Automation Excellence",
        description:
          "Enhance efficiency, reduce errors, and save time with our cutting-edge automation services.",
        image: "/images/real_world_site.jpg",
        alt: "Automation Excellence",
        tags: [
          "Process Automation",
          "Workflow Optimization",
          "Business Rule Enforcement",
        ],
      },
      {
        title: "Seamless Integration",
        description:
          "Integrate your platforms effortlessly, ensuring data flows smoothly across your organization.",
        image: "/images/integration.jpg",
        alt: "Seamless Integration",
        tags: [
          "API Integration",
          "Data Synchronization",
          "System Connectivity",
        ],
      },
      {
        title: "Visualize Success",
        description:
          "Our visualization expertise makes complex information understandable and decision-making clearer.",
        image: "/images/visualization.jpg",
        alt: "Visualize Success",
        tags: [
          "Data Visualization",
          "Interactive Dashboards",
          "Business Intelligence",
        ],
      },
    ],
    benefits: [
      {
        icon: "mdi-rocket-launch",
        title: "Rapid Development",
        description:
          "Get your solutions up and running quickly with our proven development process and product suite.",
      },
      {
        icon: "mdi-shield-check",
        title: "Enterprise-Grade Security",
        description:
          "Rest easy knowing your systems are built with industry-leading security practices and standards.",
      },
      {
        icon: "mdi-arrow-decision",
        title: "Flexible Solutions",
        description:
          "Custom solutions that adapt to your business needs, whether cloud-based or on-premises.",
      },
    ],
  }),
};
</script>

<style scoped>
.home-wrapper {
  background: #ffffff;
}

.hero-section {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  min-height: 70vh;
  display: flex;
  align-items: center;
}

.min-height-70vh {
  min-height: 70vh;
}

.devcentre-intro {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #2ab3b8;
}

.devcentre-subintro {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #37474f;
}

.hero-image {
  transform: perspective(1000px) rotateY(-15deg);
  transition: transform 0.5s ease;
}

.hero-image:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.feature-card {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

.features-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.bg-gradient {
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.gap-4 {
  gap: 1rem;
}

@media (max-width: 960px) {
  .devcentre-intro {
    font-size: 2.5rem;
  }

  .hero-section {
    min-height: auto;
    padding: 4rem 0;
  }

  .min-height-70vh {
    min-height: auto;
  }
}
</style>
