<template>
  <v-card :image="assistant_image" style="border: 2px solid #2ab3b8">
    <v-card-text class="chatbox" style="height: 400px; overflow-y: auto">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="message.user ? 'user-message' : 'assistant-message'"
      >
        <v-card
          variant="tonal"
          class="mb-2 message-box"
          v-html="message.text"
          rounded
        >
        </v-card>
      </div>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="24"
      ></v-progress-circular>
    </v-card-text>
    <v-card-actions class="px-2">
      <v-text-field
        v-model="newMessage"
        :placeholder="prompt_text"
        solo
        rounded
        color="grey lighten-2"
        append-icon=""
        bg-color="white"
        class="solid-background"
        @keyup.enter="sendMessage"
        :disabled="!prompt_active"
      >
        <template v-slot:append>
          <v-btn @click="sendMessage" :disabled="!prompt_active"
            >SEND
            <v-icon size="32" color="primary">mdi-send-circle</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
/* global gtag */
import APIService from "@/services/APIService";
import { marked } from "marked";

export default {
  name: "App",
  components: {},
  props: {
    prompt_text: {
      type: String,
      required: false,
      default: "Prompt?",
    },
    assistant_id: {
      type: String,
      required: false,
      default: "",
    },
    greeting: {
      type: String,
      required: false,
      default: "",
    },
    assistant_image: {
      type: String,
      required: false,
      default: "./images/call_center_white.jpg",
    },
  },
  data: () => ({
    newMessage: "",
    session_id: "",
    messages: [],
    message_count: 0,
    prompt_active: true,
    loading: false,
  }),
  methods: {
    async sendMessage() {
      const message = this.newMessage.trim();

      if (message === "") return;

      this.loading = true;

      this.message_count++;

      this.newMessage = "";
      this.messages.push({ user: true, text: message });
      this.$nextTick(() => {
        const chatbox = this.$el.querySelector(".chatbox");
        chatbox.scrollTop = chatbox.scrollHeight;
      });

      try {
        if (this.session_id === "") {
          const response = await APIService.start();
          this.session_id = response.data.session_id;
          gtag("event", "ads_conversion_Submit_lead_form_1");

          // Add LinkedIn tracking
          if (window.lintrk) {
            window.lintrk("track", {
              conversion_id: 18162820,
            });
          }
        }

        APIService.prompt(this.session_id, this.assistant_id, message).then(
          (response) => {
            var text_to_push = marked(response.data.text);
            this.messages.push({ user: false, text: text_to_push });
            this.$nextTick(() => {
              const chatbox = this.$el.querySelector(".chatbox");
              chatbox.scrollTop = chatbox.scrollHeight;
              this.loading = false;
            });
          }
        );
      } catch (error) {
        console.error(error);
        this.messages.push({
          user: false,
          text: "I'm, sorry, I'm having difficulties. Please try again later.",
        });
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.greeting) {
      this.messages.push({ user: false, text: this.greeting });
    }
  },
};
</script>

<style scoped>
.user-message,
.assistant-message {
  padding: 0 16px;
  margin: 12px 0;
}

.user-message {
  color: rgb(33, 102, 60);
  display: flex;
  justify-content: flex-end;
}

.assistant-message {
  color: rgb(29, 90, 107);
  display: flex;
  justify-content: flex-start;
}

.no-border .v-input__control {
  border-bottom: none !important;
}

.message-box {
  display: inline-block;
  padding: 8px 16px;
  margin: 0;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  max-width: 80%;
  text-align: left;
  border-radius: 18px !important;
}

/* Add these new classes */
.user-message .message-box {
  background-color: rgb(205, 255, 208) !important;
  border-top-right-radius: 4px !important;
}

.assistant-message .message-box {
  background-color: rgb(175, 244, 255) !important;
  border-top-left-radius: 4px !important;
}

.image-margin {
  margin: 10px;
  align-self: flex-start;
}
</style>
