import axios from "axios";

const apiClient = axios.create({
  baseURL: "/api",
  withCredentials: false,
});

export default {
  start() {
    return apiClient.get("/start-session");
  },
  sns(subject, message) {
    return apiClient.post(
      "/sns",
      { prompt: message },
      {
        params: {
          subject: subject,
        },
      }
    );
  },
  prompt(session_id, assistant_id, message) {
    return apiClient.post(
      "/prompt",
      { prompt: message },
      {
        params: {
          session_id: session_id,
          assistant_id: assistant_id,
        },
      }
    );
  },
  registerUser(token) {
    return apiClient.post("/users/register", token);
  },
  getCurrentUser(token) {
    return apiClient.get("/users/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
