<template>
  <v-container>
    <h1 class="devcentre-intro">Sign In to DevCentre</h1>
    <p class="devcentre-subintro">
      <span v-if="!showEmailForm">
        Choose how you'd like to sign in to access your account.
      </span>
      <span v-else> Enter your email and password to sign in. </span>
    </p>
    <br />

    <v-card style="border: 2px solid #2ab3b8">
      <v-card-text>
        <!-- Main login options screen -->
        <div v-if="!showEmailForm" class="text-center">
          <v-btn
            class="mb-4 login-btn"
            variant="outlined"
            @click="signInWithGoogle"
            :loading="loading"
          >
            <v-icon start>mdi-google</v-icon>
            Continue with Google
          </v-btn>

          <br />

          <v-btn
            class="login-btn"
            variant="outlined"
            @click="showEmailForm = true"
            :loading="loading"
          >
            <v-icon start>mdi-email</v-icon>
            Continue with Email
          </v-btn>
        </div>

        <!-- Email login form -->
        <div v-else>
          <v-form @submit.prevent="handleEmailLogin">
            <v-text-field
              v-model="email"
              label="Email Address"
              type="email"
              :rules="[rules.required, rules.email]"
              :disabled="loading"
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              :rules="[rules.required]"
              :disabled="loading"
            ></v-text-field>

            <div class="text-center">
              <v-btn type="submit" class="mb-4" :loading="loading">
                <v-icon start>mdi-login</v-icon>
                Sign In
              </v-btn>

              <v-btn
                class="mb-4"
                @click="showEmailForm = false"
                :disabled="loading"
              >
                <v-icon start>mdi-keyboard-return</v-icon>
                Back to Sign In Options
              </v-btn>
            </div>
          </v-form>
        </div>

        <div v-if="error" class="error-message mt-4">
          {{ error }}
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/AuthService";
import eventBus from "@/plugins/eventBus";

export default {
  name: "LoginView",
  data: () => ({
    showEmailForm: false,
    email: "",
    password: "",
    loading: false,
    error: null,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || "Invalid email address.";
      },
    },
  }),
  methods: {
    async signInWithGoogle() {
      this.loading = true;
      this.error = null;
      try {
        await AuthService.signInWithGoogle();
        eventBus.emit("user-signed-in");
        this.$router.push({ name: "home" });
      } catch (error) {
        console.error("Google sign-in error:", error);
        this.error =
          error.message || "Failed to sign in with Google. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    async handleEmailLogin() {
      if (!this.email || !this.password) {
        this.error = "Please fill in all fields";
        return;
      }

      this.loading = true;
      this.error = null;
      try {
        await AuthService.signInWithEmail(this.email, this.password);
        eventBus.emit("user-signed-in");
        this.$router.push({ name: "home" });
      } catch (error) {
        console.error("Email sign-in error:", error);
        this.error =
          error.message || "Failed to sign in. Please check your credentials.";
      } finally {
        this.loading = false;
      }
    },

    forgotPassword() {
      // We'll implement this later
      console.log("Forgot password clicked");
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #ff5252;
  font-size: 0.875rem;
}

.login-btn {
  min-width: 250px;
  padding: 12px 24px;
  text-transform: none;
  font-size: 16px;
}
</style>
