<template>
  <v-container class="services-container">
    <!-- Hero Section -->
    <v-row class="mb-12">
      <v-col cols="12" class="text-center">
        <h1 class="text-h2 mb-6" style="color: #2ab3b8; font-weight: 500">
          Building Adaptable, Robust Solutions
        </h1>
        <p
          class="text-h5 mx-auto"
          style="max-width: 800px; color: #4a5568; line-height: 1.6"
        >
          DevCentre delivers end to end services for developing your business
          solutions. We leverage our products to minimize development time
          complexity and maximize the value to your business.
        </p>
      </v-col>
    </v-row>

    <!-- Service Steps -->
    <v-row>
      <!-- Requirements Gathering -->
      <v-col cols="12" class="mb-12">
        <v-card class="service-card overflow-hidden">
          <v-row no-gutters>
            <v-col cols="12" md="6" :order="0" :order-md="0">
              <v-img
                src="/images/user_and_professional.jpg"
                height="100%"
                class="fill-height"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col
              cols="12"
              md="6"
              :order="1"
              :order-md="1"
              class="d-flex align-center"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-4">
                  <div class="mr-4 text-h6" style="color: #2ab3b8">01</div>
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Requirements Gathering
                  </h2>
                </div>
                <p class="text-body-1" style="line-height: 1.8">
                  Gathering requirements begins with our online assistant
                  helping you explore your initial requirements. From there one
                  of our consultants will work with you to understand your
                  unique business challenges. We take the time to deeply
                  understand your unique business challenges, goals and
                  requirements. This gives you a clear, comprehensive project
                  scope.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Architecture and Design -->
      <v-col cols="12" class="mb-12">
        <v-card class="service-card overflow-hidden">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              :order="1"
              :order-md="0"
              class="d-flex align-center"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-4">
                  <div class="mr-4 text-h6" style="color: #2ab3b8">02</div>
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Architecture and Design
                  </h2>
                </div>
                <p class="text-body-1" style="line-height: 1.8">
                  Having gathered requirements we will design a solution that
                  meets your needs using our products and expert services. Our
                  key architectural principle is to avoid reinventing the wheel,
                  leveraging our products to minimize development time and
                  complexity. This approach has the benefit of minimizing
                  development time and ensuring security, scalability and
                  quality.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6" :order="0" :order-md="1">
              <v-img
                src="/images/architecture.jpg"
                height="100%"
                class="fill-height"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Accelerated Development -->
      <v-col cols="12" class="mb-12">
        <v-card class="service-card overflow-hidden">
          <v-row no-gutters>
            <v-col cols="12" md="6" :order="0" :order-md="0">
              <v-img
                src="/images/developers.jpg"
                height="100%"
                class="fill-height"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col
              cols="12"
              md="6"
              :order="1"
              :order-md="1"
              class="d-flex align-center"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-4">
                  <div class="mr-4 text-h6" style="color: #2ab3b8">03</div>
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Accelerated Development
                  </h2>
                </div>
                <p class="text-body-1" style="line-height: 1.8">
                  Where custom development is required our team will use agile
                  methodologies and test driven development to ensure high
                  quality is maintained. We'll keep you engaged and informed
                  throughout the process, with regular demos and opportunities
                  for feedback.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Deployment and Optimization -->
      <v-col cols="12" class="mb-12">
        <v-card class="service-card overflow-hidden">
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
              :order="1"
              :order-md="0"
              class="d-flex align-center"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-4">
                  <div class="mr-4 text-h6" style="color: #2ab3b8">04</div>
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Deployment and Optimization
                  </h2>
                </div>
                <p class="text-body-1" style="line-height: 1.8">
                  We can host your application on your choice of platform, from
                  Google Cloud to AWS. The solution will be is managed by our
                  team. This will provide high availability and reliability.
                  Alternatively we can deploy on premises.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6" :order="0" :order-md="1">
              <v-img
                src="/images/cloud.jpg"
                height="100%"
                class="fill-height"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Ongoing Support -->
      <v-col cols="12" class="mb-12">
        <v-card class="service-card overflow-hidden">
          <v-row no-gutters>
            <v-col cols="12" md="6" :order="0" :order-md="0">
              <v-img
                src="/images/call_center.jpg"
                height="100%"
                class="fill-height"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#2ab3b8"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col
              cols="12"
              md="6"
              :order="1"
              :order-md="1"
              class="d-flex align-center"
            >
              <div class="pa-8">
                <div class="d-flex align-center mb-4">
                  <div class="mr-4 text-h6" style="color: #2ab3b8">05</div>
                  <h2 class="text-h4" style="color: #2ab3b8">
                    Ongoing Support
                  </h2>
                </div>
                <p class="text-body-1" style="line-height: 1.8">
                  We offer flexible support options to keep your solution
                  running smoothly, as well as ongoing enhancements to adapt to
                  your evolving needs. Our products are clustered and highly
                  redundant, ensuring your software does not experience outages.
                  We also offer ongoing call support to ensure your business is
                  never left in the lurch.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- CTA Section -->
    <v-row class="mt-6">
      <v-col cols="12">
        <v-card color="#2ab3b8" dark class="text-center pa-12">
          <h2 class="text-h3 mb-6">Ready to Get Started?</h2>
          <p class="text-h6 mb-8">
            Let's discuss how we can help bring your vision to life.
          </p>
          <v-btn
            x-large
            outlined
            @click="$router.push('/assistant')"
            class="mr-4"
          >
            Try Our Assistant
            <v-icon right>mdi-robot</v-icon>
          </v-btn>
          <v-btn x-large outlined @click="$router.push('/contact')">
            Contact Us
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ServicesView",
};
</script>

<style scoped>
.services-container {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.service-card {
  border: 2px solid #2ab3b8;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

@media (max-width: 960px) {
  .text-h2 {
    font-size: 2.5rem !important;
  }

  .text-h4 {
    font-size: 1.75rem !important;
  }
}
</style>
