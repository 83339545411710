<template>
  <v-container class="landing-container">
    <!-- Top Section: Hero + CTA + Demo -->
    <v-row class="align-center mb-12">
      <v-col cols="12" md="6" class="pr-md-8">
        <h1 class="text-h3 font-weight-bold mb-4" style="color: #2ab3b8">
          Transform Market Research with Dynamic Insight
        </h1>
        <span class="text-h4 font-weight-bold" style="color: #56a941"
          >Limited Time Beta Offer:</span
        ><br />
        <p class="text-h6 mb-4">
          Join our beta program to get 3 months FREE access. <br />Shape the
          future of AI-powered customer interviews that deliver deeper insights.
        </p>

        <!-- Primary CTA -->
        <v-card
          flat
          class="pa-4 mb-6"
          style="border: 2px solid #2ab3b8; border-radius: 8px"
        >
          <v-form @submit.prevent="handleSubmit" v-if="!submitted">
            <v-text-field
              v-model="name"
              label="Your Name"
              required
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Your Email"
              type="email"
              required
              outlined
              dense
            ></v-text-field>
            <v-btn
              block
              color="green lighten-1"
              class="mt-4"
              type="submit"
              :disabled="!isFormValid"
            >
              Get Early Access
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-form>
          <v-alert v-else type="success" text prominent class="mt-4">
            Thanks for joining! We'll be in touch soon with your beta access
            details.
          </v-alert>
        </v-card>
      </v-col>

      <!-- AI Demo -->
      <v-col cols="12" md="6">
        <v-card flat style="border: 2px solid #2ab3b8; border-radius: 8px">
          <v-card-title>Help Shape Our AI Research Tool</v-card-title>
          <v-card-text class="pa-4">
            <AIAssistant
              :prompt_text="'Share your ideas here...'"
              :assistant_id="'asst_4vZaRyNbZ0KmcwRwLb01bUXZ'"
              :greeting="'Tell me what features you\'d want in an AI market research tool. Your input will help shape our beta program!'"
              :assistant_image="'/images/relaxed_character.jpg'"
            ></AIAssistant>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Benefits Section -->
    <v-row id="benefits" class="mb-12">
      <v-col cols="12">
        <h2 class="text-h4 text-center mb-8" style="color: #2ab3b8">
          Why Choose Dynamic Insight?
        </h2>
        <v-row>
          <v-col
            v-for="(benefit, index) in benefits"
            :key="index"
            cols="12"
            md="3"
          >
            <v-card
              flat
              style="border: 2px solid #2ab3b8; border-radius: 8px"
              class="pa-4 h-100"
            >
              <v-icon color="#2ab3b8" size="24" class="mb-2">{{
                benefit.icon
              }}</v-icon>
              <h3 class="text-h6 mb-2">{{ benefit.title }}</h3>
              <p class="body-1">{{ benefit.description }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Metrics Section -->
    <v-row id="metrics" class="mb-12">
      <v-col cols="12">
        <v-card color="#2ab3b8" dark class="pa-8">
          <v-row>
            <v-col
              v-for="(metric, index) in metrics"
              :key="index"
              cols="12"
              md="4"
              class="text-center"
            >
              <h3 class="text-h3 mb-2">{{ metric.stat }}</h3>
              <p class="body-1">{{ metric.description }}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Features Section -->
    <v-row id="features" class="mb-12">
      <v-col cols="12">
        <h2 class="text-h4 text-center mb-8" style="color: #2ab3b8">
          Powerful Features
        </h2>
        <v-row>
          <v-col
            v-for="(feature, index) in features"
            :key="index"
            cols="12"
            md="4"
          >
            <v-card
              flat
              style="border: 2px solid #2ab3b8; border-radius: 8px"
              class="pa-4 h-100"
            >
              <v-icon color="#2ab3b8" size="32" class="mb-4">{{
                feature.icon
              }}</v-icon>
              <h3 class="text-h6 mb-2">{{ feature.title }}</h3>
              <p class="body-1">{{ feature.description }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Use Cases Section -->
    <v-row id="use-cases" class="mb-12">
      <v-col cols="12">
        <h2 class="text-h4 text-center mb-8" style="color: #2ab3b8">
          Use Cases
        </h2>
        <v-row>
          <v-col
            v-for="(useCase, index) in useCases"
            :key="index"
            cols="12"
            sm="6"
          >
            <v-card
              flat
              style="border: 2px solid #2ab3b8; border-radius: 8px"
              class="pa-4 h-100"
            >
              <v-row no-gutters>
                <v-col>
                  <h3 class="text-h6 mb-2">{{ useCase.title }}</h3>
                  <p class="body-1">{{ useCase.description }}</p>
                </v-col>
                <v-col cols="auto">
                  <v-icon color="#2ab3b8">mdi-arrow-top-right</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Secondary CTA -->
    <v-row class="mb-12">
      <v-col cols="12" sm="8" offset-sm="2">
        <v-card
          flat
          class="text-center pa-8"
          style="border: 2px solid #2ab3b8; border-radius: 8px"
        >
          <h2 class="text-h5 mb-4">Ready to Transform Your Market Research?</h2>
          <v-btn
            large
            color="#2ab3b8"
            class="white--text"
            @click="scrollTo('top')"
          >
            Join Beta Program
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AIAssistant from "@/components/AIAssistant";
import APIService from "@/services/APIService";

export default {
  name: "DynamicInsightLanding",
  components: {
    AIAssistant,
  },
  data() {
    return {
      name: "",
      email: "",
      submitted: false,
      quickLinks: [
        { text: "Benefits", target: "benefits" },
        { text: "Features", target: "features" },
        { text: "Use Cases", target: "use-cases" },
      ],
      benefits: [
        {
          title: "Natural Conversations",
          description: "Get richer insights through AI-driven natural dialogue",
          icon: "mdi-chat-processing",
        },
        {
          title: "Higher Engagement",
          description: "Achieve better response and completion rates",
          icon: "mdi-chart-bar",
        },
        {
          title: "Honest Feedback",
          description: "Receive more detailed, candid responses",
          icon: "mdi-brain",
        },
        {
          title: "Time Efficient",
          description: "Save significant time compared to traditional methods",
          icon: "mdi-clock-fast",
        },
      ],
      features: [
        {
          title: "Adaptive AI Interviews",
          description:
            "Intelligent conversations that adjust based on responses",
          icon: "mdi-play-circle",
        },
        {
          title: "Quick Setup",
          description: "Start collecting insights in minutes",
          icon: "mdi-flash",
        },
        {
          title: "Advanced Analytics",
          description: "Comprehensive analysis and reporting tools",
          icon: "mdi-chart-line",
        },
      ],
      metrics: [
        {
          stat: "3x",
          description: "More detailed responses than traditional surveys",
        },
        {
          stat: "Minutes",
          description: "To set up your research, not hours",
        },
        {
          stat: "100%",
          description: "Understanding of the 'why' behind decisions",
        },
      ],
      useCases: [
        {
          title: "Product Feedback",
          description: "Validate features and gather product insights",
        },
        {
          title: "Customer Satisfaction",
          description: "Understand and improve customer experience",
        },
        {
          title: "Market Research",
          description: "Conduct in-depth market analysis",
        },
        {
          title: "User Experience",
          description: "Gather detailed UX feedback",
        },
      ],
    };
  },
  computed: {
    isFormValid() {
      return this.name && this.email;
    },
  },
  methods: {
    handleSubmit() {
      if (this.isFormValid) {
        const to_send = `Name: ${this.name}\nEmail: ${this.email}\n`;
        APIService.sns(`Insight Beta Signup: ${this.name}`, to_send).then(
          (response) => {
            console.log(response.data);
          }
        );
        this.submitted = true;
      }
    },
    scrollTo(target) {
      const element = document.getElementById(target);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else if (target === "top") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.landing-container {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
  min-height: 100vh;
  padding-top: 2rem;
}

.v-card {
  transition: all 0.3s ease;
}

.v-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 25px rgba(42, 179, 184, 0.1);
}

@media (max-width: 1264px) {
  .landing-container {
    padding-top: 1rem;
  }
}
</style>
