<template>
  <v-card
    style="border: 2px solid #2ab3b8"
    title="Seamless System Integration Services"
    text="Integrate your platforms effortlessly, ensuring data flows smoothly across your organization."
  >
    <img src="./images/integration.jpg" alt="Developer" width="90%" />
  </v-card>
</template>

<script></script>
